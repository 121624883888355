import React from 'react';

const Spinner = ({ size }) => (
    <div
        className={`spinner-border ${!!size ? `spinner-border-${size}` : ''}`}
        role="status"
    >
        <span className="visually-hidden">Loading...</span>
    </div>
);

export default Spinner;