import React from 'react';

export const RichText = ({ content }) => {
    
    return (
        <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="rich-text text-break"
        />
    );
}

export default RichText;