import { createContext, useContext } from 'react';

export const PageContext = createContext(null);
export const UserContext = createContext(null);
export const CaseContext = createContext(null);
export const SectionContext = createContext(null);
export const UploadContext = createContext(null);
export const ToastContext = createContext(false);

export function useToastContext() {
    const context = useContext(ToastContext);

    if (!context) {
        throw new Error('toast context must be present');
    }

    return context;
}
