import { gql } from '@apollo/client';

export const CASE_STATUS_FIELDS = gql`
    fragment CaseStatusFields on Case {
        id
        status
        clientInquiryStatus
        isEditable
        isApproved
        isBuiltInNext
        isClientInquiries
        canStartReport
        canSubmit
        canStartQc
        canSendback
        canApprove
        canSuperApprove
        canClientInquiry
        canWriteFeedback
        canEditImprovementFeedback
        canPassFailFeedback
        canResolveFeedback
        canToggleBuiltInNext
        canUpdateReportScore
        canRetractApproval
        canEditCaseDetails
        canSearchTloData
        activeQcId
        caseNotes
    }
`;

export const CASE_NETWORK_INDEX_FIELDS = gql`
    fragment CaseNetworkIndexFields on CaseNetwork {
        id
        networkName
        category
        categoryId
        matchResult
        displayName
        ordinality
        link
        isBusiness
    }
`;

export const WORK_SESSION_FIELDS = gql`
    fragment WorkSessionFields on WorkSession {
        id
        startTime
        endTime
        durationInSeconds
        isActive
        formatStartDate
        formatStartTime
        formatEndTime
    }
`;

export const USER_PROFILE_FIELDS = gql`
    fragment UserProfile on User {
        id
        fullname
        nameInitials
        profileColor
        smallProfilePic
        userType
    }
`;

export const CASE_FIELDS = gql`
    ${CASE_STATUS_FIELDS}
    ${CASE_NETWORK_INDEX_FIELDS}
    ${USER_PROFILE_FIELDS}
    fragment CaseFields on Case {
        ...CaseStatusFields
        id
        name
        profileId
        useAlternateLayout
        formattedDueAt
        formattedAdjustedDueAt
        currentProofingSessionIndex
        product {
            id
            name
        }
        pdfReportType
        agentId
        assignedQcId
        client {
            id
            name
            folderId
            customerSuccessManager
        }
        isUrgent
        isFirstOrder
        isOnHold
        isTraining
        isDataClientUpdated
        caseNetworks {
            ...CaseNetworkIndexFields
        }
        categories {
            id
            name
        }
        supportLinks
        instructionsSet
        isConverting
        isTest
        isGenerating
        score
        scoreRemarks
        orderId
        usernames
        chatsCount
        templateName
        caseSearchStrings {
            networkId
            networkName
            searchStrings
        }
        feedbacks {
            id
            verdict
            status
            section
            sectionId
            isUnresolved
            isResolved
        }
        contributors {
            id
            accessType
            isAssignedAgent
            isAssignedQc
            avatarRingColor
            user {
                ...UserProfile
            }
        }
        timelineSummary {
            remarks
            dateOrdered
            submitted {
                ...TimelineSummaryFields
            }
            failed {
                ...TimelineSummaryFields
            }
            approved {
                ...TimelineSummaryFields
            }
            completed {
                ...TimelineSummaryFields
            }
        }
        currentUserApprovals {
            id
            isRetracted
            isSentBack
        }
        isNewTemplate
        isProgressive
        isIq
        isFindingsEnabled
        profilePhoto {
            variantUrl
        }
        defaultPhoto
        clientHighlightRequest
        canOverrideTimeTracker
        feedbackCountSummary {
            edit {
                user {
                    ...UserProfile
                }
                count
            }
            improvement {
                user {
                    ...UserProfile
                }
                count
            }
        }
        confidenceScoreSummary {
            user {
                ...UserProfile
            }
            confidenceScore
        }
        webformSettings
        adjustedDueAt
        isManuallyAdjusted
    }
    fragment TimelineSummaryFields on TimelineSummaryItems {
        date
        createdBy
        user {
            ...UserProfile
        }
    }
`;

export const FEEDBACK_FIELDS = gql`
    fragment FeedbackFields on Feedback {
        id
        verdict
        status
        section
        sectionId
        fieldName
        messages {
            user
            body
            formattedCreatedAt
        }
        formattedCreatedAt
        isResolved
        isUnresolved
        user {
            id
            fullname
        }
        failures
        improvements
        isClientInquiry
        resolvedByUser {
            id
            fullname
        }
        proofingSessionIndex
    }
`;

export const FEEDBACK_FIELDS_V2 = gql`
    ${USER_PROFILE_FIELDS}
    fragment FeedbackFields on Feedback {
        id
        verdict
        status
        sectionName
        fieldName
        content
        timestamp
        failures
        improvements
        sectionFieldKey
        user {
            ...UserProfile
        }
        resolvedByUser {
            ...UserProfile
        }
    }
`;

export const ATTACHMENT_FIELDS = gql`
    fragment AttachmentFields on ActiveStorageAttachment {
        filename
        url
        fileSize
        variantUrl
    }
`;

export const SCREENSHOT_FIELDS = gql`
    fragment ScreenshotFields on Screenshot {
        id
        imageFile {
            filename
            url
            fileSize
            verticalVariantUrl
            basename
        }
        externalLink
        startDate
        endDate
        reason
        metadata {
            filename
            url
        }
        videoFiles {
            filename
            url
            signedId
        }
        formatCreatedAt
        formatStartDate
        formatEndDate
        position
        isPinned
        description
        findingsCategories {
            id
            name
        }
    }
`;

export const CASE_NETWORK_FIELDS = gql`
    ${SCREENSHOT_FIELDS}
    ${ATTACHMENT_FIELDS}
    fragment CaseNetworkFields on CaseNetwork {
        id
        networkName
        isHighlightFound
        link
        profilePhoto {
            ...AttachmentFields
        }
        presence
        summary
        isBusiness
        isDirty
        matchResult
        displayName
        activityLevel
        activitySummary
        hasProfileLink
        hasProfilePhoto
        hasAttachments
        hasScreenshots
        hasFriendsFollowers
        hasActivitySummary
        findingsDocuments {
            id
            document {
                filename
                url
            }
        }
        screenshots {
            ...ScreenshotFields
        }
        friendsFollowersLists{
            filename
            url
        }
    }
`;

export const USER_FIELDS = gql`
    fragment UserFields on User {
        id
        isDev
        isAdmin
        isSuperAdmin
        canReadSummary
        canViewFeedbackPanel
    }
`;

export const TEMPLATE_FIELDS = gql`
    fragment TemplateFields on TinymceTemplate {
        id
        title
        description
        content
    }
`;

export const CASE_DETAILS = gql`
    fragment CaseDetails on Case {
        id
        name
        productId
        product {
            id
            name
        }
        orderType
        searchDateStart
        formatSearchDateStart
        alias
        age
        dateOfBirth
        dateOfLoss
        formatDateOfBirth
        formatDateOfLoss
        gender
        templateName
        clientDetails {
            emails
            phones {
                type
                phoneNumber
            }
            addresses
            knownSocialUrls
            employments
            schools
            educations {
                institution
                institutionType
                city
                degreeType
                yearGraduated
            }
            relationships
            ethnicity
            physicalDescriptions
            referralSource
            companyName
            ssn
        }
        claimDetails {
            claimNumber
            claimId
            claimType
            dateOfInjury
            parseDateOfInjury
        }
        instructionsSet
        isClientInquiries
        isIq
        isProgressive
        canEditCaseDetails
        client {
            id
            hasReferralSource
            hasCompanyName
        }
    }
`;

export const PROFILE_INFORMATION = gql`
    ${ATTACHMENT_FIELDS}
    fragment ProfileInformation on Case {
        id
        client {
            id
        }
        name
        emailAddress
        profilePhoto {
            ...AttachmentFields
        }
        findingsSummary
        mapViewPhoto {
            ...AttachmentFields
        }
        streetViewPhoto {
            ...AttachmentFields
        }
        relatives {
            id
            name
            relation
            link
            photo {
                ...AttachmentFields
            }
        }
        webformSettings
        isEditable
        isNewTemplate
        isIq
    }
`;

export const REPORT_SUMMARIES = gql`
    ${SCREENSHOT_FIELDS}
    fragment ReportSummaries on Case {
        id
        client {
            id
        }
        name
        isMatchFound
        findingsSummary
        findingsHighlight
        canPinScreenshots
        useAlternateLayout
        isIq
        isEditable
        matchType
        profileFindingsDocuments {
            id
            document {
                filename
                url
            }
        }
        reportHighlightsScreenshots {
            ...ScreenshotFields
        }
    }
`;

export const CASE_CHAT_FIELDS = gql`
    ${USER_PROFILE_FIELDS}
    fragment CaseChatFields on CaseChat {
        id
        caseStatus
        content
        timestamp
        unixTimestamp
        parentCaseChat {
            id
            content
            attachments {
                id
            }
            author {
                id
                fullname
            }
            sectionName
        }
        attachments {
            id
            filename
            url
            fileSize
        }
        author {
            ...UserProfile
        }
        section
        caseNetworkId
        sectionName
    }
`;

export const AGENT_LOG_FIELDS = gql`
    fragment AgentLogFields on AgentLog {
        id
        logText
        actionLabel
        caseStatus
        clientInquiryStatus
        timestamp
        unixTimestamp
    }
`;