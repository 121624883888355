import React from 'react';

const Error = ({ error }) => {
    
    return (
        <div className="text-danger">
            <div className="h4">An error occurred.</div>
            <div>{error.message}</div>
        </div>
    );
}

export default Error;