import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    from,
    Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';

export const createCache = () => {
    const cache = new InMemoryCache();
    if (process.env.NODE_ENV === 'development') {
        window.secretVariableToStoreCache = cache;
    }
    return cache;
};

// getToken from meta tags
const getToken = () =>
    document.querySelector('meta[name="csrf-token"]').getAttribute('content');
const token = getToken();
const setTokenForOperation = async (operation) =>
    operation.setContext({
        headers: {
            'X-CSRF-Token': token,
        },
    });
// link with token
const createLinkWithToken = () =>
    new ApolloLink(
        (operation, forward) =>
            new Observable((observer) => {
                let handle;
                Promise.resolve(operation)
                    .then(setTokenForOperation)
                    .then(() => {
                        handle = forward(operation).subscribe({
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        });
                    })
                    .catch(observer.error.bind(observer));
                return () => {
                    if (handle) handle.unsubscribe();
                };
            })
    );

// log erors
const logError = (error) => console.error(error);
// create error link
const createErrorLink = () =>
    onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
            graphQLErrors.forEach(({ message }) =>
                logError(`[GraphQL Error] ${message}`)
            );
        }
        if (networkError) {
            logError(`[GraphQL NetworkError] ${networkError}`);
        }
    });

const createHttpLink = () =>
    new HttpLink({
        uri: '/graphql',
        credentials: 'include',
    });

export const createClient = (cache, requestLink) => {
    return new ApolloClient({
        link: from([
            createErrorLink(),
            createLinkWithToken(),
            createHttpLink(),
        ]),
        cache,
    });
};
