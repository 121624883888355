import React from 'react';
import { ACCESS_TYPES } from './dictionary';

const Contributors = ({ contributors }) => {
    const MAX = 3;

    if (!contributors.length) return null;

    return (
        <div className="d-flex align-items-center gap-2">
            <div className="d-flex align-items-center gap-1">
                {contributors.slice(0, MAX).map(contributor => (
                    <Contributor
                        contributor={contributor}
                        key={contributor.id}
                    />
                ))}
            </div>
            <ContributorsDropdown
                contributors={contributors}
            />
        </div>
    );
}

const Contributor = ({ contributor: { user, accessType, avatarRingColor } }) => {

    return (
        <div className={`border border-2 rounded-circle p-1 border-${avatarRingColor}`}>
            <div className="tooltip-container">
                <UserAvatar user={user} />
                <div className="tooltip-text tooltip-dark">
                    {user.fullname} - {ACCESS_TYPES[accessType]}
                </div>
            </div>
        </div>
    );
};

const ContributorsDropdown = ({ contributors }) => {

    return (
        <div className="dropdown">
            <button
                className="btn btn-outline-secondary btn-sm rounded-pill border-2"
                type="button"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
            >
                Show all ({contributors.length})
            </button>
            <div
                className="dropdown-menu p-3 mt-2 zindex-fixed overflow-auto"
                style={{ maxHeight: '240px' }}
            >
                <div className="d-flex flex-column gap-2">
                    {contributors.map(contributor => (
                        <ContributorDetails
                            contributor={contributor}
                            key={contributor.id}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

const ContributorDetails = ({
    contributor: {
        user,
        accessType,
        isAssignedAgent,
        isAssignedQc,
        avatarRingColor
    }
}) => {
    const designation = isAssignedAgent ? 'Primary Agent' : isAssignedQc ? 'Primary QC' : user.userType;

    return (
        <div className="d-flex align-items-center gap-3">
            <div className={`border border-2 rounded-circle p-1 border-${avatarRingColor}`}>
                <UserAvatar user={user} />
            </div>
            <div className="text-nowrap small">
                {user.fullname}
                <div className="text-muted">
                    {designation} - {ACCESS_TYPES[accessType]}
                </div>
            </div>
        </div>
    );
};

export const UserAvatar = ({ user }) => {

    return (
        <div className="avatar avatar-xs d-block">
            {!!user.smallProfilePic ? (
                <img
                    src={user.smallProfilePic}
                    className="avatar-img rounded-circle"
                />
            ) : (
                <div
                    className="avatar-img rounded-circle d-flex align-items-center justify-content-center"
                    style={{ backgroundColor: user.profileColor || 'black' }}
                >
                    <span className="fs-5 text-white">
                        {user.nameInitials}
                    </span>
                </div>
            )}
        </div>
    );
}

export default Contributors;