import { Uploader } from "../uploader";
import { ALLOWED_IMAGE_TYPES } from "./dictionary";

export function arrayReducer(state, action) {
    switch (action.type) {
        case 'add':
            return [...state, action.payload];
        case 'update':
            return state.map((object, index) => index === action.index ? action.payload : object);
        case 'delete':
            return state.filter((_, index) => index !== action.index);
        case 'initialize':
            return action.payload || [];
    }
}

export function objectsReducer(state, action) {
    switch (action.type) {
        case 'add':
            return [...state, action.payload];
        case 'update':
            return state.map(object => (
                object.id === action.payload.id ? { ...object, ...action.payload } : object
            ));
        case 'delete':
            return state.filter(object => object.id !== action.id);
        case 'initialize':
            return action.payload || [];
    }
}

export function formatBytes(bytes) {
    if (!bytes || bytes == 0) return "0 KB";

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(0))} ${sizes[i]}`;
}

export function pluralize(count, singular) {
    return `${count} ${singular}${count !== 1 ? 's' : ''}`;
}

export function reducer(state, action) {
    switch (action.type) {
        case 'add':
            return [...state, action.payload];
        case 'update':
            return state.map((object, index) => (
                index === action.index ? action.payload : object
            ));
        case 'delete':
            return state.filter((_, index) => index !== action.index);
        case 'initialize':
            return action.payload || [];
    }
}

// Return null if null; else, return undefined
// Use for file params: return null to delete file
export function isNull(object) {
    return object === null ? null : undefined;
}

export function uploadFile({ file, objectId, attribute, properties, updateObject }, dispatch) {
    if (!(file instanceof File)) return;

    const assignBlob = async (signedId, onCompleted) => {
        var variables = {
            id: objectId,
            params: {
                [attribute]: signedId,
            },
        };
        await updateObject({ variables }).then(() => onCompleted());
    }

    const uploader = new Uploader(file, dispatch, assignBlob, properties);
    uploader.start();
}

// Check if field, field array, or nested field is dirty
export function isFieldDirty(field = false) {
    if (typeof field === 'boolean') return field;

    if (Array.isArray(field)) {
        var isDirty = field.map(object => isFieldDirty(object)).flat();
        return isDirty.some(value => value === true);
    }

    var isDirty = Object.values(field).map(object => isFieldDirty(object)).flat();
    return isDirty.some(value => value === true);
}

export function isOuterClick (element, event) {
    return element && !element.contains(event.target);
}

export function isValidUrl(string) {
    if (string === "" || string == null) return true;

    let url;

    try {
        url = new URL(string);
    } catch {
        return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
}

export function validateUrl(string) {
    if (string === "" || string == null) return true;

    let url;

    try {
        url = new URL(string);
    } catch {
        return 'invalid URL';
    }

    if (url.protocol !== "http:" && url.protocol !== "https:") {
        return 'must be http or https';
    }

    var tld = url.hostname.split('.').pop();
    if (tld === 'ph') {
        return '.ph is not allowed';
    }

    if (string.match(/<!doctype html>/i)) {
        return 'contains metadata';
    }
}

// Function to validate feedback: each dirty field must have feedback
export function validateFeedback(feedbacks, dirtyFields, FIELDS) {
    return Object.values(FIELDS).every(({ field, name }) => {
        if (isFieldDirty(dirtyFields[name])) {
            return feedbacks.some(feedback => feedback.fieldName === field);
        }
        return true;
    });
}

export function getVerdict(feedbacks) {
    var isFail = feedbacks.some(feedback => !!feedback.isUnresolved);
    var isPass = feedbacks.some(feedback => feedback.verdict === 'pass');
    var isResolved = feedbacks.some(feedback => !!feedback.isResolved);

    if (isFail) return 'fail';
    if (isPass) return 'pass';
    if (isResolved) return 'resolved';
}

export function isGoogleNetwork(networkName) {
    return /Google \d*/.test(networkName);
}

export function validateImage({ name, type }) {
    if (ALLOWED_IMAGE_TYPES.includes(type)) return null;

    return `File type (${type || name}) is invalid.`;
}

export function groupObjectsByAttribute(objects, attribute) {
    return objects.reduce((groupedObjects, object) => {
        groupedObjects[object[attribute]] = (groupedObjects[object[attribute]] || []).concat(object);
        return groupedObjects;
    }, {});
}