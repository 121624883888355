import React from 'react';
import Select from 'react-select';
import { gql, useQuery } from '@apollo/client';
import { Controller } from 'react-hook-form';
import { getCaseIdFromUrl } from '../hooks';

const MessageSectionTag = ({ control }) => {
    const { data, loading } = useQuery(CASE_NETWORKS_QUERY, {
        variables: {
            id: getCaseIdFromUrl(),
        }
    });

    let options = [
        { id: 'profile_information', label: 'Profile Information' },
        { id: 'report_summaries', label: 'Report Summaries' },
    ];

    if (data) options = options.concat(data.caseNetworks);

    const styles = {
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (base) => ({ ...base, minHeight: 'unset' }),
        dropdownIndicator: (base) => ({ ...base, padding: '4px 6px' }),
        clearIndicator: (base) => ({ ...base, padding: '4px 6px' }),
    }

    return (
        <Controller
            control={control}
            name='sectionOption'
            render={({ field: { onChange, value } }) => (
                <Select
                    options={options}
                    isClearable={true}
                    isLoading={loading}
                    styles={styles}
                    menuPortalTarget={document.body}
                    maxMenuHeight={200}
                    className='col-6 ms-auto fs-5'
                    placeholder='Tag a section'
                    getOptionValue={option => option.id}
                    onChange={option => onChange(option)}
                    value={options.filter(option => option.id === value?.id)}
                />
            )}
        />
    );
}

const CASE_NETWORKS_QUERY = gql`
    query CaseNetworks($id: ID!) {
        caseNetworks: caseNetworkByCaseId(id: $id) {
            id
            label: displayName
            caseNetworkId: id
        }
    }
`;

export default MessageSectionTag;