import React, { useState } from 'react';
import { render } from 'react-dom';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import Provider from 'components/Provider';
import { getCaseIdFromUrl } from './Webform/hooks';
import MessagesPanelBody from './Webform/MessagesPanelBody';
import MessageFilters from './Webform/MessageFilters';
import Loading from './Webform/shared/Loading';
import Error from './Webform/shared/Error';
import { CLIENT_INQUIRY_STATUSES, STATUSES } from './Webform/shared/dictionary';
import NextIcon from "../../assets/images/next-icon.svg";
import LegacyIcon from "../../assets/images/legacy-icon.svg";

const CaseHistory = () => {
    const [filters, setFilters] = useState({});

    return (
        <>
            <div className="offcanvas-header p-0">
                <div className="col-6 offcanvas-header border-bottom-0">
                    <h4 className="mb-0" id="caseHistoryLabel">
                        Case History
                    </h4>
                </div>
                <div className="col-6 offcanvas-header border-bottom-0 border-start">
                    <MessageFilters
                        filters={filters}
                        setFilters={setFilters}
                    />
                    <button
                        type="button"
                        className="btn p-0 btn-sm btn-link-default"
                        data-bs-dismiss="offcanvas"
                        aria-label="Close"
                    >
                        Close
                    </button>
                </div>
            </div>
            <CaseHistoryBody filters={filters} />
        </>
    );
}

const CaseHistoryBody = ({ filters }) => {
    let caseId = getCaseIdFromUrl();

    const { data, loading, error } = useQuery(CASE_QUERY, {
        variables: {
            id: caseId,
        },
        skip: !caseId,
    });

    if (!caseId) return null;
    if (loading || error) {
        return (
            <div className="offcanvas-body">
                {loading ? <Loading /> : <Error error={error} />}
            </div>
        );
    }

    return (
        <div className="offcanvas-body p-0 row g-0 overflow-hidden">
            <div className="col-6 p-4">
                <CaseDetails _case={data.case} />
            </div>
            <div className="col-6 border-start d-flex flex-column mh-100">
                <MessagesPanelBody
                    filters={filters}
                    canClientInquiry={data.case.canClientInquiry}
                    scrollToBottom={true}
                    key={caseId}
                />
            </div>
        </div>
    );
}

const CaseDetails = ({ _case, _case: { status, clientInquiryStatus, client, claimDetails } }) => {
    const { label } = STATUSES[status] || {};
    const { label: ciLabel } = CLIENT_INQUIRY_STATUSES[clientInquiryStatus] || {};

    return (
        <div className="border rounded bg-whitesmoke p-4">
            <div className="d-flex align-items-center gap-3 flex-wrap mb-2">
                <div className="d-flex align-items-start gap-2">
                    <h5 className="fs-4 mb-0 text-break">
                        {_case.name}
                    </h5>
                    <img
                        src={_case.isBuiltInNext ? NextIcon : LegacyIcon}
                        alt="SDC-Next icon"
                        style={{ width: "18px" }}
                    />
                </div>
                <div className="d-flex gap-2">
                    {status === 'client_inquiries' ? (
                        <span className={`badge status_${clientInquiryStatus}`}>
                            {ciLabel}
                        </span>
                    ) : (
                        <span className={`badge status_${status}`}>
                            {label}
                        </span>
                    )}
                    {_case.isUrgent && (
                        <span className="badge bg-danger-soft border border-danger fw-bold">
                            RUSH
                        </span>
                    )}
                </div>
            </div>
            <div className="d-flex flex-column gap-1 small">
                <div>
                    {client.name}
                </div>
                <div className="mb-2">
                    {`Claim No. ${claimDetails.claimNumber || '-'}`}
                </div>
                <a
                    href={`/webform/${_case.id}`}
                    className="small fw-bold"
                    target="_blank"
                >
                    Open webform
                </a>
                {!!claimDetails.claimNumber && (
                    <a
                        href={`https://socialdiscoverycorp.com/admin/orders?q[person_case_id_contains]=${claimDetails.claimNumber}`}
                        className="small fw-bold"
                        target="_blank"
                    >
                        Client Facing (Active Admin)
                    </a>
                )}
            </div>
        </div>
    );
}

const CASE_QUERY = gql`
    query Case($id: ID!) {
        case(id: $id) {
            id
            name
            isBuiltInNext
            status
            clientInquiryStatus
            isUrgent
            orderId
            canClientInquiry
            client {
                id
                name
            }
            claimDetails {
                claimNumber
            }
        }
    }
`;

export function renderCaseHistory() {
    const container = document.getElementById('bs-caseHistory');

    const router = createBrowserRouter([
        {
            path: '/case_tracking',
            element: <CaseHistory />,
        },
    ]);

    if (container) {
        render(
            <Provider>
                <RouterProvider router={router} />
            </Provider>,
            container
        );
    }
}