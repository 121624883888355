import { DirectUpload } from '@rails/activestorage';
import { v4 as uuidv4 } from 'uuid';

export class Uploader {
    constructor(file, dispatch, assignBlob, properties) {
        this.upload = new DirectUpload(file, '/rails/active_storage/direct_uploads', this);
        this.payload = { id: uuidv4(), file, ...properties };
        this.dispatch = dispatch;
        this.assignBlob = assignBlob;
    }

    start() {
        var payload = {
            ...this.payload,
            progress: 0,
            status: 'pending',
            uploader: this,
        };
        this.dispatch({ type: 'add', payload });

        this.create();
    }

    retry() {
        var payload = {
            id: this.payload.id,
            progress: 0,
            status: 'pending',
        };
        this.dispatch({ type: 'update', payload });

        this.create();
    }

    create() {
        this.upload.create((error, blob) => {
            if (error) {
                this.onerror();
            } else {
                this.assignBlob(blob.signed_id, this.onsuccess.bind(this));
            }
        });
    }

    abort(request) {
        var payload = {
            id: this.payload.id,
            status: 'cancelled',
        };
        this.dispatch({ type: 'update', payload });

        request.abort();
    }

    directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener('progress', event => this.directUploadDidProgress(event));

        var payload = {
            id: this.payload.id,
            request,
        };
        this.dispatch({ type: 'update', payload });
    }

    directUploadDidProgress(event) {
        var progress = event.loaded / event.total;
        var payload = {
            id: this.payload.id,
            progress: (progress * 100).toFixed(0),
        };
        this.dispatch({ type: 'update', payload });
    }

    onsuccess() {
        var payload = {
            id: this.payload.id,
            progress: 100,
            status: 'completed',
        };
        this.dispatch({ type: 'update', payload });
    }

    onerror() {
        var payload = {
            id: this.payload.id,
            status: 'failed',
        };
        this.dispatch({ type: 'update', payload });
    }
}
