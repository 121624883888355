import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Offcanvas } from 'bootstrap';
import { useQuery } from '@apollo/client';
import { CONVERSATIONS_QUERY } from './queries';
import Loading from './shared/Loading';
import Error from './shared/Error';
import Spinner from './shared/Spinner';
import RichText from './shared/RichText';
import { UserAvatar } from './shared/Contributors';
import { CLIENT_INQUIRY_STATUSES, STATUSES } from './shared/dictionary';
import { formatBytes, objectsReducer, pluralize } from './shared/helperFunctions';
import MessageForm from './MessageForm';
import { getCaseIdFromUrl } from './hooks';

const MessagesPanelBody = ({ filters, canClientInquiry, scrollToBottom = false }) => {
    const [parentChat, setParentChat] = useState();
    const [isInquiry, setIsInquiry] = useState(false);
    const [uploads, dispatchUploads] = useReducer(objectsReducer, []);

    useEffect(() => {
        if (isInquiry && !!parentChat) setParentChat(null);
    }, [isInquiry]);

    return (
        <>
            <div
                className="offcanvas-body bg-whitesmoke"
                id="js-messagesContainer"
            >
                <Messages
                    filters={filters}
                    uploads={uploads.filter(upload => upload.status !== 'completed')}
                    setParentChat={setParentChat}
                    isInquiry={isInquiry}
                    scrollToBottom={scrollToBottom}
                />
            </div>
            <div
                className="p-4 border-top shadow-lg flex-shrink-0 overflow-y-auto"
                style={{ maxHeight: '75vh' }}
            >
                <MessageForm
                    parentChat={parentChat}
                    setParentChat={setParentChat}
                    isInquiry={isInquiry}
                    setIsInquiry={setIsInquiry}
                    canClientInquiry={canClientInquiry}
                    dispatchUploads={dispatchUploads}
                />
            </div>
        </>
    );
}

const Messages = ({ filters, uploads, setParentChat, isInquiry, scrollToBottom }) => {
    const [activeMessage, setActiveMessage] = useState();

    const { data, loading, error } = useQuery(CONVERSATIONS_QUERY, {
        variables: {
            caseId: getCaseIdFromUrl(),
        },
        onCompleted: () => handleScroll(),
    });

    const handleScroll = () => {
        const container = document.querySelector('#js-messagesContainer');
        if (scrollToBottom) {
            container.scroll({ top: container.scrollHeight, behavior: 'smooth' });
        }
    }

    if (loading) return <Loading />;
    if (error) return <Error error={error} />;

    const messages = prepareMessages(data, filters);

    if (!messages.length) {
        return <span>No messages</span>;
    }

    return (
        <div className="d-flex flex-column gap-4">
            {messages.map(message => (
                <Message
                    key={message.id}
                    message={message}
                    uploads={uploads.filter(upload => upload.chatId === message.id)}
                    setParentChat={setParentChat}
                    isInquiry={isInquiry}
                    activeMessage={activeMessage}
                    setActiveMessage={setActiveMessage}
                />
            ))}
        </div>
    );
}

const Message = ({
    message,
    uploads,
    setParentChat,
    isInquiry,
    activeMessage,
    setActiveMessage
}) => {
    const ref = useRef();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        var messageId = searchParams.get('message_id');
        if (messageId === message.id) {
            var element = document.querySelector('#webformMessages');
            var offcanvas = Offcanvas.getOrCreateInstance(element);
            offcanvas.show();
            setActiveMessage(messageId);
        }
    }, []);

    useEffect(() => {
        if (activeMessage && activeMessage === message.id) {
            ref.current.scrollIntoView({ behavior: 'smooth' });
            var timeout = setTimeout(() => setActiveMessage(null), 3000);
        }
        return () => clearTimeout(timeout);
    }, [activeMessage]);

    if (message.__typename === 'AgentLog') {
        return <Log log={message} />;
    }

    const { author, parentCaseChat, attachments } = message;
    const isActive = activeMessage === message.id;

    return (
        <div
            className={`bg-white p-4 rounded shadow border ${isActive ? 'border-dark-orange' : ''}`}
            style={{ scrollMarginTop: '1.5rem', transition: 'border-color 0.3s ease-in-out' }}
            ref={ref}
        >
            <div className="d-flex align-items-start gap-3">
                <UserAvatar user={author} />
                <div className="col-10 flex-grow-1">
                    <div className="d-flex align-items-start justify-content-between gap-3 mb-3">
                        <MessageHeader message={message} />
                        <time className="flex-shrink-0 small">
                            {message.timestamp}
                        </time>
                    </div>
                    {!!parentCaseChat && (
                        <ParentMessage
                            message={parentCaseChat}
                            setActiveMessage={setActiveMessage}
                        />
                    )}
                    {!!(attachments.length || uploads.length) && (
                        <MessageAttachments
                            attachments={attachments}
                            uploads={uploads}
                        />
                    )}
                    <div className="small">
                        <RichText content={message.content} />
                    </div>
                    <button
                        type="button"
                        className="btn btn-sm p-0 text-primary"
                        onClick={() => !isInquiry ? setParentChat(message) : null}
                        disabled={isInquiry}
                    >
                        <div className="d-flex align-items-center gap-1">
                            <i className="fe fe-message-square" />
                            Reply
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
}

const ParentMessage = ({ message, message: { attachments }, setActiveMessage }) => {

    return (
        <div
            className="border-start border-primary border-3 ps-3 mb-3"
            type="button"
            onClick={() => setActiveMessage(message.id)}
        >
            <div className="mb-2">
                <MessageHeader message={message} />
            </div>
            {!!attachments.length && (
                <AttachmentsCount attachments={attachments} />
            )}
            <div className="line-clamp small">
                <RichText content={message.content} />
            </div>
        </div>
    );
}

const MessageAttachments = ({ attachments, uploads }) => {
    
    return (
        <div className="d-flex flex-column gap-3 mb-3">
            {uploads.map(upload => (
                <Upload
                    key={upload.id}
                    upload={upload}
                />
            ))}
            {attachments.map(attachment => (
                <MessageAttachment
                    key={attachment.id}
                    attachment={attachment}
                />
            ))}
        </div>
    );
}

const MessageAttachment = ({ attachment: { filename, url, fileSize } }) => {
    
    return (
        <div className="bg-primary-soft rounded small py-2 px-3">
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-3">
                    <i className="fe fe-file fs-2" />
                    <div>
                        <div className="fw-bold text-truncate" style={{ maxWidth: '180px' }}>
                            {filename}
                        </div>
                        <div>
                            {formatBytes(fileSize)}
                        </div>
                    </div>
                </div>
                <a
                    href={`${url}?disposition=attachment`}
                    download={true}
                    className="fw-bold"
                >
                    Download
                </a>
            </div>
        </div>
    );
}

const Upload = ({ upload: { file, progress, status, uploader } }) => {
    const failed = status === 'failed';

    return (
        <div className={`bg-${failed ? 'danger-soft' : 'secondary-soft'} rounded small py-2 px-3`}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center gap-3">
                    {failed ? (
                        <i className="fe fe-alert-triangle text-danger fs-2" />
                    ) : (
                        <Spinner size="sm" />
                    )}
                    <div className={failed ? 'text-danger' : ''}>
                        <div className="fw-bold text-truncate" style={{ maxWidth: '180px' }}>
                            {file.name}
                        </div>
                        <div>
                            {failed ? (
                                'Upload failed'
                            ) : (
                                formatBytes(file.size)
                            )}
                        </div>
                    </div>
                </div>
                {failed ? (
                    <button
                        type="button"
                        className="btn btn-sm p-0 text-primary fw-bold"
                        onClick={() => uploader.retry()}
                    >
                        Retry
                    </button>
                ) : (
                    <span>{progress}%</span>
                )}
            </div>
        </div>
    );
}

const MessageHeader = ({ message, message: { author }}) => {

    return (
        <div className="text-break small">
            <span className="fw-bold">{author.fullname}</span>
            {!!message.sectionName && (
                <>
                    <span>{` on `}</span>
                    <span className="fw-bold">{message.sectionName}</span>
                </>
            )}
        </div>
    );
}

const Log = ({ log }) => {
    const { label } = STATUSES[log.caseStatus] || {};
    const { label: ciLabel } = CLIENT_INQUIRY_STATUSES[log.clientInquiryStatus] || {};

    return (
        <div>
            <div className="d-flex align-items-center justify-content-between mb-3">
                <div className="d-flex gap-3">
                    <div className={`badge status_${log.caseStatus || 'default'} fs-5`}>
                        {label || log.actionLabel}
                    </div>
                    {log.caseStatus === 'client_inquiries' && (
                        <div className={`badge status_${log.clientInquiryStatus} fs-5`}>
                            {ciLabel}
                        </div>
                    )}
                </div>
                <time className="small">{log.timestamp}</time>
            </div>
            <div className="text-break small">
                {log.logText}
            </div>
        </div>
    );
}

const AttachmentsCount = ({ attachments }) => {

    return (
        <div className="d-flex align-items-center gap-2 small mb-2">
            <i className="fe fe-paperclip" />
            <span className="fst-italic">
                {pluralize(attachments.length, 'attachment')}
            </span>
        </div>
    );
}

function prepareMessages(data, filters) {
    let messages = [];
    const { status, messageType } = filters;
    const QC_ACTIONS = ['Approve', 'Retract', 'Generate'];
    
    if (messageType === 'logs' || !messageType) {
        messages = messages.concat(data.agentLogs);
    }
    
    if (messageType === 'chats' || !messageType) {
        messages = messages.concat(data.caseChats);
    }

    if (!!status) {
        messages = messages.filter(message => {
            if (!message.caseStatus) {
                return status === 'in_qc' && QC_ACTIONS.includes(message.actionLabel);
            }
            return message.caseStatus === status
        });
    }

    return messages.sort((a, b) => a.unixTimestamp - b.unixTimestamp);
}

export default MessagesPanelBody;