// app/javascript/components/Provider/index.js
import { ApolloProvider } from '@apollo/client';
import React from 'react';
import { createCache, createClient } from 'utils/apollo';

export default ({ children }) => (
    <ApolloProvider client={createClient(createCache())}>
        {children}
    </ApolloProvider>
);
