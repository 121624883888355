import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useController, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { CONVERSATIONS_QUERY } from './queries';
import { CREATE_CASE_CHAT, START_CLIENT_INQUIRY, UPDATE_CASE_CHAT } from './mutations';
import { SectionContext } from './context';
import ClientInquiryPanel from './ClientInquiryPanel';
import MessageTextEditor from './MessageTextEditor';
import RichText from './shared/RichText';
import MessageSectionTag from './shared/MessageSectionTag';
import { formatBytes, pluralize, uploadFile } from './shared/helperFunctions';
import { getCaseIdFromUrl } from './hooks';

const MessageForm = ({
    parentChat,
    setParentChat,
    isInquiry,
    setIsInquiry,
    canClientInquiry,
    dispatchUploads
}) => {
    const editorRef = useRef(null);
    const { activeSection } = useContext(SectionContext) || {};
    const [error, setError] = useState();
    let params = useParams();
    let caseId = getCaseIdFromUrl();

    // If active section is set, set as value of section tag
    useEffect(() => {
        const { id, key } = activeSection || {};
        if (key) setValue('sectionOption', { id: id || key, caseNetworkId: params.networkId });
    }, [activeSection]);

    // If parent chat is set, set section of parent chat as value of section tag
    useEffect(() => {
        const { section, caseNetworkId } = parentChat || {};
        if (section) {
            setValue('sectionOption', { id: caseNetworkId || section, caseNetworkId });
        }
    }, [parentChat]);

    const { control, setValue, handleSubmit, watch, resetField } = useForm();
    const { field: content } = useController({
        control,
        name: 'content',
        defaultValue: '',
    });
    const { fields, append, remove, replace } = useFieldArray({
        control,
        name: 'attachments',
        keyName: 'key'
    });

    const [createCaseChat, { loading }] = useMutation(CREATE_CASE_CHAT, {
        onCompleted: () => handleSuccess(),
        onError: (error) => handleError(error),
        update(cache, { data: { createCaseChat } }) {
            updateCache(cache, caseId, createCaseChat);
        }
    });
    const [updateCaseChat] = useMutation(UPDATE_CASE_CHAT);

    const [startClientInquiry, { loading: inquiryLoading }] = useMutation(START_CLIENT_INQUIRY, {
        onCompleted: () => handleSuccess(),
        onError: (error) => handleError(error),
        update(cache, { data: { startClientInquiry } }) {
            updateCache(cache, caseId, startClientInquiry);
        },
        refetchQueries: ['WorkSessions'],
    });

    const onSubmit = async ({ content, sectionOption, clientInquiryStatus, attachments }) => {
        if (isInquiry) {
            var variables = {
                id: caseId,
                clientInquiryStatus,
            }
            if (validateContent(editorRef.current) || !!attachments.length) {
                variables.chatParams = { content };
            }
            await startClientInquiry({ variables })
                .then(({ data: { startClientInquiry: { caseChat } } }) => {
                    if (caseChat) handleUpload(caseChat.id, attachments);
                });
            return;
        }

        var variables = {
            params: {
                caseId,
                content,
                parentCaseChatId: parentChat?.id,
                ...sectionParams(sectionOption),
            }
        }
        await createCaseChat({ variables })
            .then(({ data: { createCaseChat: { caseChat } } }) => {
                handleUpload(caseChat.id, attachments);
            });
    }

    const handleSuccess = () => {
        setParentChat(null);
        resetField('content');
        resetField('sectionOption');
        replace([]);
        setError(null);

        // Scroll to end of messages
        const element = document.querySelector('#js-messagesContainer');
        if (element) {
            element.scrollTop = element.scrollHeight;
        }

        // Show alert to reload page
        const alert = document.querySelector('#js-reloadPageAlert');
        if (alert && isInquiry) {
            alert.classList.remove('d-none');
        }
    }

    const handleError = (error) => {
        setError(error);
        throw error;
    }

    const handleDrop = (e) => {
        e.preventDefault();
        Array.from(e.dataTransfer.files).forEach(file => {
            // Compatibility with TinyMCE
            if (!(file instanceof File)) {
                Object.setPrototypeOf(file, File.prototype);
            }
            append({ file });
        });
    }

    const handleAttach = (e) => {
        Array.from(e.target.files).forEach(file => {
            append({ file });
        });
    }

    const handleUpload = (chatId, attachments) => {
        const uploads = attachments.map(attachment => (
            {
                file: attachment.file,
                objectId: chatId,
                attribute: 'attachment',
                properties: { chatId },
                updateObject: updateCaseChat,
            }
        ));
        uploads.forEach(upload => uploadFile(upload, dispatchUploads));
    }

    const canSend = isInquiry ? true : validateContent(editorRef.current) || fields.length;

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex align-items-center mb-3">
                <button
                    type="button"
                    className={`btn btn-sm rounded-0 rounded-start py-1 btn-${!isInquiry ? 'secondary' : 'outline-secondary'}`}
                    onClick={() => setIsInquiry(false)}
                >
                    <div className="d-flex align-items-center gap-2">
                        <i className="fe fe-message-square" />
                        <span>Chat</span>
                    </div>
                </button>
                <button
                    type="button"
                    className={`btn btn-sm rounded-0 rounded-end py-1 btn-${isInquiry ? 'secondary' : 'outline-secondary'}`}
                    onClick={() => setIsInquiry(true)}
                    disabled={!canClientInquiry}
                >
                    <div className="d-flex align-items-center gap-2">
                        <i className="fe fe-help-circle" />
                        <span>Client inquiry</span>
                    </div>
                </button>
                {!isInquiry && (
                    <MessageSectionTag control={control} />
                )}
            </div>
            <div
                className="border border-secondary-soft rounded"
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => handleDrop(e)}
            >
                {!!parentChat && (
                    <Reply
                        message={parentChat}
                        setParentChat={setParentChat}
                    />
                )}
                {isInquiry && (
                    <ClientInquiryPanel
                        option={watch('clientInquiryStatus')}
                        setOption={(option) => setValue('clientInquiryStatus', option)}
                    />
                )}
                {!!fields.length && (
                    <div className="d-flex gap-3 flex-wrap pt-3 px-3">
                        {fields.map((field, index) => (
                            <Attachment
                                key={field.key}
                                file={field.file}
                                remove={() => remove(index)}
                            />
                        ))}
                    </div>
                )}
                <div className="tinymce-border-0 mb-3">
                    <MessageTextEditor
                        editorRef={editorRef}
                        value={content.value}
                        onChange={content.onChange}
                        onDrop={handleDrop}
                    />
                </div>
                <div className="d-flex align-items-end justify-content-between px-3 pb-3">
                    <label className="btn p-2 lh-1 bg-whitesmoke">
                        <i className="fe fe-paperclip fs-3" />
                        <input
                            className="d-none"
                            type="file"
                            multiple={true}
                            onChange={(e) => handleAttach(e)}
                        />
                    </label>
                    <div />
                    <button
                        type="submit"
                        className="btn btn-sm btn-dark"
                        disabled={!canSend || loading || inquiryLoading}
                    >
                        Send
                    </button>
                </div>
            </div>
            {!!error && (
                <div className="text-danger small">
                    {error.message}
                </div>
            )}
        </form>
    );
}

const Reply = ({ message, message: { author, attachments }, setParentChat }) => {

    return (
        <div className="border-start border-primary border-3 ps-3 mt-3 mx-3">
            <div className="d-flex align-items-center justify-content-between">
                <div className="col-10">
                    <div className="h5 text-break mb-2">
                        Reply to {author.fullname}
                    </div>
                    {!!attachments.length && (
                        <AttachmentsCount attachments={attachments} />
                    )}
                    <div className="line-clamp small">
                        <RichText content={message.content} />
                    </div>
                </div>
                <button
                    type="button"
                    className="btn p-2 lh-1 bg-whitesmoke"
                    onClick={() => setParentChat(null)}
                >
                    <i className="fe fe-x" />
                </button>
            </div>
        </div>
    );
}

const Attachment = ({ file: { name, size }, remove }) => {

    return (
        <div className="badge bg-primary-soft text-dark">
            <div className="d-flex align-items-center gap-2">
                <div className="d-flex align-items-baseline gap-1">
                    <div className="text-truncate lh-base" style={{ maxWidth: '200px' }}>
                        {name}
                    </div>
                    <span>{`(${formatBytes(size)})`}</span>
                </div>
                <button
                    className="btn p-0 lh-1"
                    type="button"
                    onClick={remove}
                >
                    <i className="fe fe-x" />
                </button>
            </div>
        </div>
    );
}

const AttachmentsCount = ({ attachments }) => {

    return (
        <div className="d-flex align-items-center gap-2 small mb-2">
            <i className="fe fe-paperclip" />
            <span className="fst-italic">
                {pluralize(attachments.length, 'attachment')}
            </span>
        </div>
    );
}

function updateCache(cache, caseId, result) {
    const { caseChat, agentLog } = result;

    const query = {
        query: CONVERSATIONS_QUERY,
        variables: {
            caseId,
        },
    }
    const data = cache.readQuery(query);

    if (data) {
        cache.writeQuery({
            ...query,
            data: {
                caseChats: caseChat ? [...data.caseChats, caseChat] : data.caseChats,
                agentLogs: agentLog ? [...data.agentLogs, agentLog] : data.agentLogs,
            }
        });
    }

    if (caseChat) {
        cache.modify({
            id: `Case:${caseId}`,
            fields: {
                chatsCount(cachedChatsCount = 0) {
                    return cachedChatsCount + 1;
                }
            }
        });
    }
}

function validateContent(editor) {
    if (!editor) return false;

    var content = editor.getContent({ format : 'text' });
    return !!content.trim();
}

function sectionParams(option) {
    if (!option) return {};
    return {
        section: option.caseNetworkId ? 'case_network' : option.id,
        caseNetworkId: option.caseNetworkId,
    }
}

export default MessageForm;