import React, { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import Loading from './shared/Loading';
import Error from './shared/Error';
import { getCaseIdFromUrl } from './hooks';

const ClientInquiryPanel = ({ option, setOption }) => {
    const { data, loading, error } = useQuery(CASE_QUERY, {
        variables: {
            id: getCaseIdFromUrl(),
        }
    });

    useEffect(() => {
        const currentStep = CLIENT_INQUIRY_OPTIONS[clientInquiryStatus]?.step || 0;
        const nextStep = currentStep + 1 < 3 ? currentStep + 1 : 0;
        const nextOption = Object.keys(CLIENT_INQUIRY_OPTIONS).find(key => CLIENT_INQUIRY_OPTIONS[key].step === nextStep);
        setOption(nextOption);
    }, [data]);

    if (loading) return <Loading />;
    if (error) return <Error error={error} />;

    const { clientInquiryStatus } = data.case;

    return (
        <div>
            <div className="row align-items-center gx-3 p-3">
                <div className="col-auto fw-bold small">Switch report status to</div>
                <div className="col-6">
                    <ClientInquiryDropdown
                        option={option}
                        setOption={setOption}
                        clientInquiryStatus={clientInquiryStatus}
                    />
                </div>
            </div>
            <p className="border-top border-bottom small p-3 mb-0">
                {CLIENT_INQUIRY_OPTIONS[option]?.message}
            </p>
        </div>
    );
}

const ClientInquiryDropdown = ({ option, setOption, clientInquiryStatus }) => {
    const currentStep = CLIENT_INQUIRY_OPTIONS[clientInquiryStatus]?.step || 0;

    return (
        <div className="dropdown d-grid">
            <button
                className="btn btn-sm border rounded d-flex align-items-center justify-content-between dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
            >
                <OptionLabel option={option} />
            </button>
            <div className="dropdown-menu w-100 p-3">
                <div className="d-flex flex-column gap-2">
                    {Object.keys(CLIENT_INQUIRY_OPTIONS).map(key => (
                        <ClientInquiryOption
                            key={key}
                            option={key}
                            setOption={setOption}
                            currentStep={currentStep}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

const ClientInquiryOption = ({ option, setOption, currentStep }) => {
    const { step, separator } = CLIENT_INQUIRY_OPTIONS[option] || {};

    return (
        <>
            <button
                className="btn btn-sm p-0"
                type="button"
                disabled={currentStep === step || (currentStep === 0 && step === 2)}
                onClick={() => setOption(option)}
            >
                <OptionLabel option={option} />
            </button>
            {separator && <div className="border-bottom" />}
        </>
    );
}

const OptionLabel = ({ option }) => {
    const { label, color } = CLIENT_INQUIRY_OPTIONS[option] || {};
    
    return (
        <div className="d-flex align-items-center gap-2">
            {!!color && (
                <div
                    className={`bg-${color} rounded-pill`}
                    style={{ width: '12px', height: '12px' }}
                />
            )}
            <span>{label}</span>
        </div>
    );
}

const CASE_QUERY = gql`
    query Case($id: ID!) {
        case(id: $id) {
            id
            clientInquiryStatus
        }
    }
`;

const CLIENT_INQUIRY_OPTIONS = {
    data_confirmation: {
        label: 'Data Confirmation',
        color: 'info',
        message: 'Pause encoding and start a client inquiry.',
        step: 1,
    },
    request_for_extension: {
        label: 'Request for Extension',
        color: 'info',
        message: 'Pause encoding and start a client inquiry.',
        step: 1,
    },
    client_request: {
        label: 'Client Request',
        color: 'info',
        message: 'Restart encoding due to a client request.',
        step: 1,
        separator: true,
    },
    awaiting_response: {
        label: 'Awaiting Response',
        color: 'warning',
        message: 'Inquiry sent to client and awaiting response.',
        step: 2,
        separator: true,
    },
    resume_encoding: {
        label: 'Return to agent',
        message: 'Return to agent to resume encoding.',
        step: 0,
    },
    resume_qc: {
        label: 'Return to QC',
        message: 'Return to QC to resume proofing.',
        step: 0,
    },
}

export default ClientInquiryPanel;