import { useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from '@apollo/client';
import { WORK_SESSIONS_QUERY } from './queries';
import { CaseContext } from './context';

export function arrayReducer(state, action) {
    switch (action.type) {
        case 'add':
            return [...state, ''];
        case 'update':
            return state.map((item, index) => index === action.index ? action.payload : item);
        case 'delete':
            return state.filter((_, index) => index !== action.index);
        case 'initialize':
            return action.payload || [];
    }
}

export function objectsReducer(state, action) {
    switch (action.type) {
        case 'add':
            return [...state, action.payload];
        case 'update':
            return state.map(object => (
                object.id === action.payload.id ? { ...object, ...action.payload } : object
            ));
        case 'delete':
            return state.filter(object => object.id !== action.id);
        case 'initialize':
            return action.payload || [];
    }
}

export function scrollToElementOnload(dependency) {
    useEffect(() => {
        const { hash } = location;

        if (!!hash) {
            const element = document.querySelector(hash);
            element?.scrollIntoView({ behavior: 'smooth' });
        } else {
            const section = document.querySelector('#webformSection');
            var scrollPosition = document.documentElement.scrollTop;
            var sectionPosition = section?.getBoundingClientRect().top;
            if (scrollPosition > sectionPosition) {
                section?.scrollIntoView();
            }
        }
    }, dependency);
}

export function setPageTitle(name, section) {
    useEffect(() => {
        if (!!name && !!section) {
            document.title = `${name} - ${section}`;
        }
    }, [name, section]);
}

export function useTimerStatus() {
    const { canOverrideTimeTracker } = useContext(CaseContext);
    if (canOverrideTimeTracker) return true;

    let params = useParams();
    const { data } = useQuery(WORK_SESSIONS_QUERY, {
        variables: {
            caseId: params.id,
        },
    });

    return data?.workSessions.some(session => session.isActive);
}

export function getCaseIdFromUrl() {
    let params = useParams();
    const { hash } = location;
    if (params.id) return params.id;
    return /\d+/.exec(hash)?.[0];
}